import React from 'react'
import Helmet from 'react-helmet'

import { graphql } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components'

import Layout from '../components/Layout'

class Resume extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const siteKeywords = get(
      this,
      'props.data.site.siteMetadata.siteKeywords'
    )
    const resume = get(this, 'props.data.allResumeJson.edges')[0].node;
    const image = require('../assets/img/circleshot.png');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <FlexGrid className="resume">
          <div className="container-fluid">
            <div className="row main clearfix">
              <section className="col-md-4 card-wrapper profile-card-wrapper affix background-details">
                <div className="card">
                  <div className="detail" id="education">
                    <div className="icon">
                      <i className="fs-lg icon-pencil"></i>
                      <span className="mobile-title">Education</span>
                    </div>
                    <div className="info">
                      <h4 className="title text-uppercase">Education</h4>
                      <div className="content">
                        <ul className="list-unstyled clear-margin">
                          {resume.education.map((ed) => {
                            return (
                              <li className="card-nested" key={ed.institution}>
                                <div className="content">
                                  <p className="clear-margin relative"><strong>{ed.area},&nbsp;</strong>{ed.institution}</p>
                                  <p className="text-muted"><small>{ed.startDate}- {ed.endDate}</small></p>
                                  <i>4.0</i>
                                  <div className="space-top labels">
                                    {ed.courses.map((course) => {
                                      return  (
                                        <span className="label label-keyword" key={course}>{course}</span>
                                      )
                                    })}
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="detail" id="skills">
                    <div className="icon">
                      <i className="fs-lg icon-book"></i>
                      <span className="mobile-title">Skills</span>
                    </div>
                    <div className="info">
                      <h4 className="title text-uppercase">Skills</h4>
                      <div className="content">
                        <ul className="list-unstyled clear-margin">
                          {resume.skills.map((skill) => {
                            return (
                              <li className="card-nested card-skills" key={skill.name}>
                                <div className="skill-level" title="">
                                  <div className={`skill-progress ${skill.level}`}></div>
                                </div>
                                <div className="skill-info">
                                  <strong>{skill.name}</strong>
                                  <div className="space-top labels">
                                    {skill.keywords.map((keyword) => {
                                      return (
                                        <span className="label label-keyword" key={keyword}>{keyword}</span>
                                      )
                                    })}
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="col-md-8 card-wrapper pull-right background-details">
                <div className="detail card" id="work-experience">
                  <div className="icon">
                    <i className="fs-lg icon-office"></i>
                    <span className="mobile-title">Work Experience</span>
                  </div>
                  <div className="info">
                    <h4 className="title text-uppercase">Work Experience</h4>
                    <ul className="list-unstyled clear-margin">
                      {resume.work.map((job) => {
                        return (
                          <li className="card card-nested clearfix" key={job.company}>
                            <div className="content">
                              <p className="clear-margin relative">
                                <strong>{job.position}</strong>,
                                  &nbsp;<a href={job.website} target="_blank">{job.company}</a>
                              </p>
                              <p className="text-muted">
                                <small>
                                  <span className="space-right">{job.startDate} - {job.endDate}</span>
                                  <span><i className="icon-clock mr-5"></i>1 year</span>
                                </small>
                              </p>
                              <div className="mop-wrapper space-bottom">
                                <p>{job.summary}</p>
                              </div>
                              <ul className="highlights">
                                {job.highlights.map((highlight, idx) => {
                                  return (
                                    <li className="mop-wrapper" key={idx}>
                                      <p>{highlight}</p>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </FlexGrid>
      </Layout>
    )
  }
}

export default Resume

const FlexGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
`

export const pageQuery = graphql`
   query {
     site {
       siteMetadata {
         title
         description
         siteKeywords
       }
     }
     allResumeJson {
       edges {
         node {
           basics {
             name
             label
             picture
             email
             phone
             website
             summary
             location {
               address
               postalCode
               city
               countryCode
               region
             }
             profiles {
               network
               username
               url
             }
           }
           work {
             company
             position
             startDate
             endDate
             summary
             highlights
           }
           volunteer {
             organization
             position
             website
             startDate
             endDate
             summary
             highlights
           }
           education {
             institution
             area
             studyType
             startDate
             endDate
             gpa
             courses
           }
           awards {
             title
             date
             awarder
             summary
           }
           skills {
             name
             level
             keywords
           }
           languages {
             language
             fluency
           }
           interests {
             name
           }
         }
       }
    }
  }
`
